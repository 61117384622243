import { Loading } from '../components/Loading';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '../components/OktaContext';
import { ErrorMessage } from '../components/ErrorMessage';
import Head from 'next/head';

interface OktaError {
  name?: string;
  errorCode?: string;
  errorSummary?: string;
}

const Login: NextPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { push } = useRouter();

  useEffect(() => {
    const getToken = async () => {
      // If the urs is a redirect from Okta, get the token from URL and set that
      if (oktaAuth?.isLoginRedirect()) {
        try {
          const { tokens } = await oktaAuth.token.parseFromUrl();
          oktaAuth.tokenManager.setTokens(tokens);
        } catch (error) {
          setErrorMessage(
            (error as OktaError)?.errorSummary ??
              'We’re having trouble getting this information, please try again later.'
          );
        }
      } else {
        // If the user is visiting url directly, check if idToken and accessToken is in local storage
        const idToken = await oktaAuth?.tokenManager.get('idToken');
        const accessToken = await oktaAuth?.tokenManager.get('accessToken');
        // Store those tokens in okta tokenManager
        oktaAuth?.tokenManager.setTokens({ accessToken, idToken });
        // If no token, go a redirect.
        if (!idToken || !accessToken) {
          oktaAuth?.token.getWithRedirect({
            pkce: true,
            scopes: ['openid', 'profile', 'email']
          });
        }
      }
    };

    getToken();
  }, [oktaAuth]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      push(oktaAuth?.getOriginalUri() || '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.isAuthenticated]);

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <div className="h-screen flex flex-col items-center justify-center">
        {errorMessage ? (
          <ErrorMessage
            errorMessage={`${errorMessage} Contact Support@org-crowley.com for access.`}
          />
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default Login;
